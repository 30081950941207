import * as React from 'react';
import './App.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

const questions = [
  {
    title: 'BCPの作成状況について教えてください',
    answers: [
      {
        score: 0,
        label: 'まだBCPを作成していない(または作成途中)',
      },
      {
        score: 3,
        label: '自然災害、感染症のBCPどちらかを作成している',
      },
      {
        score: 5,
        label: '自然災害と感染症のBCP両方を作成している',
      },
    ],
    advice: [
      '最低限、自然災害と感染症の両方のBCPを作成することをおすすめします。',
    ],
  },
  {
    title: 'BCPをどのように保管していますか(またはする予定ですか)',
    answers: [
      {
        score: 0,
        label: '未定',
      },
      {
        score: 0,
        label: '紙書類として保管',
      },
      {
        score: 3,
        label: '職場のパソコンに保管',
      },
      {
        score: 5,
        label: 'いつでも、どこからでも確認できるように保管',
      },
    ],
    advice: [
      'BCPはアクシデント発生時、いつでも・誰でも閲覧できる状態になければ機能しない恐れがあります',
    ],
  },
  {
    title: 'BCPを用いた災害訓練の準備はできていますか',
    answers: [
      {
        score: 0,
        label: '訓練の準備はできていない',
      },
      {
        score: 5,
        label: '訓練の準備ができている(または訓練を行った実績がある)',
      },
    ],
    advice: [
      'BCPを作成しただけではアクシデント発生時に機能しない恐れがあります',
      'BCPを用いた訓練を行うことで、BCPの不備を発見し、改善することができます',
    ],
  },
  {
    title: '災害発生時、職員の安否確認を行う方法がありますか',
    answers: [
      {
        score: 0,
        label: '安否確認の方法を用意していない',
      },
      {
        score: 3,
        label: '職員の連絡網を作成している',
      },
      {
        score: 5,
        label: '安否確認のためのサービスを利用している',
      },
      {
        score: 3,
        label: '上記以外の安否確認の方法を用意している',
      },
    ],
    advice: [
      '職員の安否確認の方法は必ず準備しておく必要があります',
      'また、連絡網だけでは、情報の集計や連絡自体に非常に手間がかかり、到達率にも不安があります',
      '緊急時でも手間なく安否確認ができる方法を用意することをお勧めします',
    ],
  },
  {
    title: '災害発生時、事業復旧のために何をすべきか職員が把握できていますか',
    answers: [
      {
        score: 0,
        label: '把握できていないと思う',
      },
      {
        score: 3,
        label: '管理職などの一部の職員は把握できてる',
      },
      {
        score: 5,
        label: '職員一人ひとりが把握できている',
      },
    ],
    advice: [
      '災害発生時、迅速に事業復旧を行うためには、職員一人ひとりが何をすべきか把握、あるいは確認できる状態にしておくことが重要です',
      '迅速な事業復旧は、関係取引からの信用獲得に大きく貢献します',
    ],
  },
];

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  const initialValues = questions.map(() => {
    return 0;
  });
  const navigate = useNavigate();

  const [values, setValues] = React.useState(initialValues);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = Number(event.target.value);
    values[index] = value;
    setValues([...values]);
  };

  const calcScore = () => {
    const total = values.reduce((acc, cur, index) => {
      return acc + questions[index].answers[cur].score;
    }, 0);
    const ave = total / questions.length;
    let score = 'D'
    if (ave === 5) {
      score = 'A'
    } else if (ave >= 4) {
      score = 'B'
    } else if (ave >= 3) {
      score = 'C'
    } else {
      score = 'D'
    }
    const advices: string[] = [];
    questions.forEach((question, index) => {
      if (question.answers[values[index]].score < 5) {
        advices.push(...question.advice);
      }
    });
    navigate('/result', {state: {score: score, advices: advices}});
  };

  return (
    <div className="App">
      <div className="header">
        <a href="https://www.cloud-bcp.com/">
          <img className="logo" src="/logo.png" alt="クラウドビーシーピー" />
        </a>
      </div>
      <div className="main">
        <Card className="description">
          <CardContent>
            <Typography className="title" variant="h5" component="h1">
              BCP診断
            </Typography>
            <Typography>
              質問に回答すると、あなたの企業のBCP活動が診断されます。
              <br />
              診断結果に基づき、改善に向けたアドバイスもご確認いただけます。
            </Typography>
          </CardContent>
        </Card>
        <Card className="card">
          <CardContent>
            {questions.map((question, i) => (
              <div className="question_wrapper" key={i}>
                <div className="question_title">
                  {i + 1}. {question.title}
                </div>
                <div className="answers">
                  <FormControl>
                    <RadioGroup onChange={(e) => handleChange(e, i)}>
                      {question.answers.map((answer, index) => (
                        <FormControlLabel
                          key={index}
                          checked={values[i] === index}
                          value={index}
                          control={<Radio />}
                          label={answer.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ))}
          </CardContent>
          <CardActions>
            <div className="diagnose_btn" onClick={calcScore}>診断する</div>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}

function Result() {
  interface State {
    score: string;
    advices: string[];
  }

  const setResultText = () => {
    if (score === 'A') {
      return 'あなたのBCP活動はとても優秀です。是非とも継続して対策を行い、アクシデントに強い企業づくりを行ってください。';
    } else if (score === 'B') {
      return 'あなたのBCP活動は比較的良質です。不足している部分を補うことで、アクシデントに強い企業づくりを行えます';
    } else if (score === 'C') {
      return 'あなたのBCP活動はまだまだ改善の余地があります。BCPの作成・見直しを行い、適切な対策を行わなければ、災害その他のアクシデントにより事業継続が困難となる恐れがあります。';
    } else {
      return 'あなたの企業は、災害その他のアクシデントにより事業継続が困難となる可能性が非常に高い状態です。企業や職員を守るためにもBCPの作成を行い、適切な備えを行うことでアクシデントに強い企業づくりを行えます。';
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { score, advices } = location.state as State;
  if (!score) {
    navigate('/');
  }
  const resultText = setResultText();

  return (
    <div className="result">
      <div className="result_main">
        <div className="result_title">診断結果</div>
        <div className="result_contents">
          <div className="result_score_wrapper">
            <div>スコア</div>
            <div>
              <img
                className="result_image"
                src={`/score_${score}.png`}
                alt="スコア"
              />
            </div>
          </div>
          <div className="result_text">{resultText}</div>
          {score !== 'A' ? (
            <>
              <Card className="advice_card">
                <div className="advice_title">アドバイス</div>
                <CardContent>
                  <ul>
                    {advices.map((advice, index) => (
                      <li className="advice_item" key={index}>
                        {advice}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
              <div className="recommend_text">
                <img
                  className="logo"
                  src="/logo_2.png"
                  alt="クラウドビーシーピー"
                />
                なら
                <br />
                これら全ての課題を解決できます！
              </div>
            </>
          ) : (
            <div className="recommend_text">
              <img
                className="logo"
                src="/logo_2.png"
                alt="クラウドビーシーピー"
              />
              なら
              <br />
              あなたのBCP活動をさらに強化できます！
            </div>
          )}
          <div>
            <a href="https://www.cloud-bcp.com/#form">
              <div className="contact_btn">CloudBCPの資料請求はこちら</div>
            </a>
          </div>
        </div>
      </div>
      <div className="result_footer">
        <Link to="/" className="back_btn">
          <KeyboardDoubleArrowLeftIcon />
          BCP診断に戻る
        </Link>
      </div>
    </div>
  );
}

export default App;
